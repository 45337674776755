import React from "react"

import SectionHeader from "./section-header"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Reveal , Animation} from "react-genie"
import imgMeritTime from "../images/merit_time.jpg"
import imgMeritPrice from "../images/merit_price.jpg"
import imgMeritCsv from "../images/merit_output_csv.jpg"

const divider = 
  <div style={{marginBottom:"4rem"}}>
    <hr style={{margin:"0 auto"}}></hr>
  </div>
;

const MainMerit = (props) => (
  <div className="main-merit" style={{ 
      padding: "4rem 0 0 0",
      backgroundColor: "#f2f2f2",
      textAlign:"center"
    }}>
    <div id="main-merit-register-item-master" className="main-merit-container">
      <SectionHeader
          title="大幅な時間短縮"
          description="面倒な棚卸もらくらく"
        />
      <div className="main-merit-content" style={{margin:"0 auto", textAlign:"left"}}>
        <ol>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>検算不要</li>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>数量を入力するだけ</li>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>複数端末で作業できる（有料プランのみ）</li>
          <li style={{textAlign:"center"}}><img src={imgMeritTime}></img></li>
        </ol>
      </div>
    </div>
    {divider}
    <div id="main-merit-tanaoroshi" className="main-merit-container">
      <SectionHeader
          title="販売価格・仕入価格の合計が一目でわかる"
          description="面倒くさい検算が不要"
        />
      <div className="main-merit-content" style={{margin:"0 auto", textAlign:"left"}}>
        <ol>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>端末の棚卸データが確認できる</li>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>商品総数量が確認できる</li>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>商品検索ができる</li>
          <li style={{textAlign:"center"}}><img src={imgMeritPrice}></img></li>
        </ol>
      </div>
    </div>
    {divider}
    <div id="main-merit-tanaoroshi" className="main-merit-container">
      <SectionHeader
          title="CSV出力が可能"
          description=""
        />
      <div className="main-merit-content" style={{margin:"0 auto", textAlign:"left"}}>
        <ol>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>クラウド管理が可能</li>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>複数端末の棚卸データを集中管理</li>
          <li style={{textAlign:"center"}}><img src={imgMeritCsv}></img></li>
        </ol>
      </div>
    </div>
    {divider}
    <div id="main-merit-tanaoroshi" className="main-merit-container">
      <SectionHeader
          title="バーコードリーダーと接続可能"
          description="※動作確認済み：Inateck BCST-70、RIOTEC iDC9277A"
        />
      <div className="main-merit-content" style={{margin:"0 auto", textAlign:"left"}}>
        <ol>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>小さいバーコードも読み取りやすい</li>
          <li><FontAwesomeIcon icon={faCheck} style={{marginRight:15, color:"skyblue"}}/>読み取りがスピーディーに</li>
        </ol>
        <div className="main-merit-video-wrapper" style={{margin:"auto 0", textAlign:"left"}}>
          <h4>比較例</h4><br/>
          <div> {/* ↓youtubeの埋め込みリンクをコピペしclassName="video-usage-iframe"をつけて,もともとついてたwidthとheightを消す。allowfullscreenとframeborderをキャメルケースにする */}
            <iframe className="video-merit-iframe" src="https://www.youtube.com/embed/0ouK_k3eqt8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p>スマホカメラ：4分23秒</p>
          </div>
          <div> {/* ↓youtubeの埋め込みリンクをコピペしclassName="video-usage-iframe"をつけて,もともとついてたwidthとheightを消す。allowfullscreenとframeborderをキャメルケースにする */}
            <iframe className="video-merit-iframe" src="https://www.youtube.com/embed/5LwcoM7NzWA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p>バーコードリーダー(ガンタイプ)：2分30秒</p>
          </div>
          <div> {/* ↓youtubeの埋め込みリンクをコピペしclassName="video-usage-iframe"をつけて,もともとついてたwidthとheightを消す。allowfullscreenとframeborderをキャメルケースにする */}
            <iframe className="video-merit-iframe" src="https://www.youtube.com/embed/g8sXyGIsS6A" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p>バーコードリーダー(センサータイプ)：2分3秒</p>
          </div>
          <div>
            センサータイプのバーコードリーダー(RIOTEC iDC9277A)は、弊社で販売も行っております。<br/>
            <a href="https://forms.gle/9AzsHoN9UfSBoqQDA">RIOTEC iDC9277A 購入フォーム</a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default MainMerit
