import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import HeroMerit from "../components/hero-merit";
import MainMerit from "../components/main-merit";

import {RevealGlobalStyles} from "react-genie";

const Merit = () => (
  <Layout>
    <RevealGlobalStyles />
    <SEO />
    <Header siteTitle="バーコードde実棚 | 特徴・メリット"/>
    <HeroMerit />
    <MainMerit />
  </Layout>
)

export default Merit
