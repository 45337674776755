import React from "react"
import PropTypes from "prop-types"

import Button from "../components/button"
import keyVisual from "../images/kv2.jpg"

import { Reveal } from "react-genie"

const HeroMerit = ({ siteTitle }) => (
  <div
    style={{
      backgroundImage: `url(${keyVisual})`,
      //backgroundImage: `url(${"https://via.placeholder.com/1470x1033"})`,
      backgroundSize: "cover",
      backgroundPosition: "center"
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "4rem 1rem",
        background:"rgba(0, 0, 0, .3)"
      }}
    >
      <div
        style={{
          position: "relative",
          top: 0,
          height: "100vh",
          width: "100vw",
        }}
      >
        <div className="hero-absolute-div">
          <Reveal>
            <h1>特徴・メリット</h1>
          </Reveal>
          <Reveal delay="100">
            <p>
              バーコードde実棚の特徴・メリット
            </p>
          </Reveal>
          <Reveal  delay="200">
            <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white" }}>
              <Button>
                無料ではじめる
              </Button>
            </a>
          </Reveal>
          <Reveal  delay="200">
            <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white"}}>
              <Button>
                有料会員になる
              </Button>
            </a>
          </Reveal>
        </div>
      </div>
    </div>
  </div>
)

HeroMerit.propTypes = {
  siteTitle: PropTypes.string,
}

HeroMerit.defaultProps = {
  siteTitle: ``,
}

export default HeroMerit
